
.App {
  text-align: center;
  background-color: #daf7fe !important;
  height:900px;
}
body{
  font-family:"Calibri, sans-serif" !important;
  /* background-color: #daf7fe !important; */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Home_Icons{
  display: flex;
  align-items: center;
  flex: auto;
  justify-content: center;
  flex-direction: column;
  row-gap: 15px;
  cursor: pointer;
  font-weight: 500;
}

.Home_Icons > a{
  display: flex;
  align-items: center;
  flex: auto;
  justify-content: center;
  flex-direction: column;
  row-gap: 15px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-weight: 500;
}
.Home_Icons:hover{
  color: #488ee7;
  cursor: pointer;
}

.MoneyTransfersIcons{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
}

.mobileNumber{
  width: 300px !important;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.MoneyTransfers{
  background-color: #fff;
  border-radius: 10px;
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
}

.MoneyTransfersHeading{
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.ToMobile{
 text-align: center;
}

.ToMobileInput{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.input-group{
  /* flex-wrap: nowrap; */
}

.contact-number{
  width: 100% !important;
}

.MoneyTransfersTitle{
  font-size: 22px;
  font-weight: 500;
}

.MoneyTransfersPermaLink{
  display: flex;
  align-items: center;
  color: #488ee7;
}

.HeaderWrapper{
  display: flex;
  justify-content: space-between;
  padding: 35px;
}

.payment-services{
  background-color: #21376d;
  color: #fff;
  font: 1.3em sans-serif;
  padding: 5px;
  display: flex;
  justify-content: space-around;
}

.mode-of-transaction{
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #4cbaeb;
  border-radius: 5px;
  padding: 3px;
}

.Personal-mode-of-transaction{
  background-color: white;
  width: 50%;
  border-radius: 5px;
  padding: 5px;
}

.Business-mode-of-transaction{
  width: 50%;
  padding: 5px;
}

.HeaderLogo{
  font-size: 35px;
}

.ToMobileWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

}

.backward-page{
  display: flex;
  justify-content: flex-start;
}

.PrimaryAccountWrapper{
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

.PrimaryAccountInputUserLink{
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
}

.PrimaryAccountSenderFirstLetter , .PrimaryAccountReceiverFirstLetter{
  width: 70px;
  height: 70px;
  padding: 10px;
  border: 3px solid #22376d;
  color: #22376d;
  text-align: center;
  border-radius: 50px;
  font-size: 50px;
  line-height: 40px;
  background-color: #4bbfee;
}

.PrimaryAccountInputCard{
  background-color: #22376d;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  margin: 25px;
}

.cardName{
  font-size: 25px;
  font-weight: 100;
}

.receiverName{
  font-size: 50px;
  font-weight: 500;
}

.receiverName25{
  font-size: 25px;
  font-weight: 400;
}

.receiverBankDetails{
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.receiverBankDetailsColumn{
  display: flex;
  flex-direction: column;
}

.input-group-text{
  border-radius: 1px !important;
}

.form-control{
  background-color: #d8f7fe !important;
}

.PrimaryAccountInputDetails{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 25px 0px ;
}

.PrimaryAccountEnterAmount , .PrimaryAccountDescription{
  font-size: 25px;
}

.amountWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.amountTitle{
  display: flex;
}

.amountCode{
  color: #4a5d8d;
  font-size: 50px;
  font-weight: 500;
  margin: 0 10px 0 10px;
}

.amountActual{
  color: #22376d;
  font-size: 50px;
  font-weight: 500;
}

.PrimaryAccountInputCardWithOutStyle{
  margin: 15px 0;
}

.amountWrapper{
  margin: 15px 0;
}

.amountDetails{
  display: flex;
  flex-direction: column;
}

.amountInWords{
  display: flex;
  column-gap: 15px;
  font-size: 20px;
}

.amountDescription{
  display: flex;
  column-gap: 15px;
  font-size: 20px;
}

.amountInWordsText , .amountDescriptionValue{
  font-weight: 400;
}

.amountCodeFullForm, .amountDescriptionLabel{
  font-weight: 200;
}
.timeDateFormat{
  font-size: 15px;
}

.transactionProcessingFeeHeader{
  background-color: #ddf7ff;
  padding: 15px;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
}

.transactionProcessingFeeHeaderLable{
  font-weight: 100;
}

.transactionProcessingFeeHeaderValue{
  font-weight: 500;
  margin-left: 10px;
}

.payUsing{
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  width: 41%;
}

.payUsingHeadline{
  font-size: 25px;
  text-align: left;
}

.payUsingLinkedAccounts{
  font-size: 18px;
  background-color: #50b7e9;
  padding: 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: 500;
}
.payLinkToText{
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
}

.linkedAccountList{
  padding: 15px;
}

.linkedAccountListUl{
  list-style: none;
  text-align: left;
}

.nestedList{
  border-bottom: 3px solid #daf7ff;
  padding: 15px;
}

.nestedAccountWrapper{
  display: flex;
  justify-content: space-between;
}

.nestedAccountName{
  display: flex;
}

.checkBalance{
  padding: 5px 0 0 25px;
  color: #62b9ea;
  cursor: pointer;
}

.successPaymentHeader{
  background-color: #f2f2f2;
  padding: 15px;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
}

.successPaymentHeaderLable{
  font-weight: 500;
}

.successPaymentHeaderValue{
  font-weight: 500;
  margin-left: 10px;
}

.SuccessPaymentWrapper, .FailurePaymentWrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  background-color: #ddf7ff;
  padding: 15px;
}

.successPaymentDetailsWrapper{
  font-size: 15px;
}

.SuccessPaymentInputUserLink , .FailurePaymentInputUserLink{
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  gap: 20px;
}

.SuccessPaymentReceiverFirstLetter, .FailurePaymentReceiverFirstLetter{
  width: 70px;
  height: 70px;
  padding: 10px;
  border: 3px solid #fff;
  color: #22376d;
  text-align: center;
  border-radius: 50px;
  font-size: 50px;
  line-height: 40px;
  background-color: #fff;
}

.receiverNameWrapper{
  display: flex;
  flex-direction: column;
  text-align: left;
}

.successFullMessage, .failureFullMessage{
  display: flex;
  column-gap: 15px;
  font-size: 20px;
  justify-content: center;
}

.successFullMessageDetails , .failureMessageDetails{
  display: flex;
  column-gap: 15px;
  font-size: 20px;
  flex-direction: column;
  text-align: inherit;
}

.requestSuccessfull{
  margin-top: 15px;
}

.refShareWrapper{
  display: flex;
  flex-direction: row;
  justify-content:space-around;
}

.bottomSpace{
  margin-bottom: 25px;
}

.share , .payAgain{
  padding: 15px;
  background-color: #fff;
  border-radius: 15px;
  width: 125px;
}

.failureMessage{
  color: red;
}

.commentWrapper{
  padding: 35px;
}

.commentBorderd{
  border: 5px solid #ddf7fe;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  gap: 15px;
  min-height: 125px;
}

.commentReceiver{
  display: flex;
  gap: 5px;
}

.commentNestedWrapper{
  display: flex;
  gap: 15px;
}

.commentReceiver > .dottedMessage {
  color: #696969;
  padding: 5px;
  border:3px dotted #e3e3e3 ;
  border-radius: 5px;
}

.TranasactionFailureLable{
  font-size: 25px;
  font-weight: 500;
}

.TranasactionFailureMessage{
  font-size: 20px;
  font-weight: 100;
}

.errorWrapper{
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 25px;
}

.countryCode > .form-control{
  pointer-events: none;
}

.payButton{
  width: 50px;
  display: flex;
  align-self: center;
  text-decoration: none;
}

.country-list{
  text-align: left;
}

.HeaderNotifications{
  cursor: pointer;
}